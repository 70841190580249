<template>
  <div
    v-loading="isPlansLoading"
    class="h-full"
  >
    <div
      v-if="props.plans?.length"
      class="space-y-3 pb-6"
    >
      <PlanItem
        v-for="plan in props.plans"
        :key="plan._id"
        :plan="plan"
        clickable
        data-cy="plan"
        @click="emit('set-plan', plan)"
      />
    </div>

    <EmptyState
      v-else-if="!isPlansLoading"
      icon="package-x"
      :title="useT('abc868')"
      :description="useAuthStore().user?.role === 'admin' ? useT('abc869') : useT('abc870')"
      class="h-full"
    >
      <el-tooltip
        :content="useT('abc1101')"
        :disabled="useAuthStore().user?.role === 'admin'"
      >
        <Button
          type="primary"
          :disabled="useAuthStore().user?.role !== 'admin'"
          data-cy="open-plan-order-dialog"
          @click="() => {
            useDialogStore().close('eventCreate');
            useDialogStore().open('settings', {
              currentTab: 'plans',
            });
          }"
        >
          {{ useT('abc1084') }}
        </Button>
      </el-tooltip>
    </EmptyState>
  </div>
</template>

<script setup>
const emit = defineEmits(['set-plan']);

const props = defineProps({
  isPlansLoading: {
    type: Boolean,
    default: false,
  },

  plans: {
    type: Array,
    default: () => [],
  },
});
</script>
